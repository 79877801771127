import React from "react";
import styled from "styled-components";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { HighlightedCategory, MonthFavorability } from "@hyphen-lib/domain/resource/report/common/Highlights";
import { getOr, isNotNullNorUndefined } from "@hyphen-lib/lang/Objects";
import { Heading } from "@components/core/Typography";
import { FavorabilityScore } from "@components/core/FavorabilityScore";
import CompareValue from "@components/core/CompareValue";
import { LineChart, LineChartData } from "@components/core/LineChart";
import Palette, { getBarThemeForEnps, getBarThemeForFavorability } from "@src/config/theme/palette";
import Category from "@screens/Insights/Survey/components/OverviewReport/components/Overview/components/Category";
import { extractComparison } from "hyphen-lib/dist/business/calculation/benchmark/Benchmarks";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CompareWithOption } from "@screens/Insights/components/ViewOptions/components/CompareWith";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { CategorySection, generateSegmentSubtitle } from "@src/utils/Subtitles";
import { CompanyResource } from "hyphen-lib/dist/domain/resource/CompanyResource";
import { isPulsePollEnabled } from "hyphen-lib/dist/business/company/Companies";
import { Filter } from "@src/components/core/FilterLabels/types";
import { shouldShowModuleCount, shouldShowSectionBasedonFilter } from "@src/utils/modules";
import { ScoreType } from "hyphen-lib/dist/domain/resource/report/common/Score";
import { DashboardLikes } from "hyphen-lib/dist/domain/resource/report/common/DashboardLikes";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { FocusArea } from "hyphen-lib/dist/domain/FocusArea";
import FavorabilityOreNPSInfoModal from "@src/components/core/InfoModal";
import {
  FavorabilityDistributionGraphContainer,
  HeadingSubTitle,
  HeadingSubTitleUnderline
} from "@src/screens/Insights/Survey/components/OverviewReport/components/Overview";
import { trans } from "@src/utils/i18next";
import { calculateNpsDistribution, generateDistributionText } from "@src/utils/eNpsAndFavDistributionUtils";
import { NPSSummary } from "@src/screens/Insights/PulsePoll/components/PulsePollAnswerDistribution";
import { QuestionType } from "hyphen-lib/dist/domain/common/QuestionType";
import { DistributionResult } from "hyphen-lib/dist/domain/resource/report/common/DistributionResult";
import { adjustElementsAndPercentages } from "@src/utils/Graphs";
import { StackedBarGraph } from "@src/components/core/StackedBarGraph";
interface Props extends WithTranslation {
  readonly data: DashboardLikes.Favorability;
  readonly enps?: DashboardLikes.ENPS;
  readonly compareWithOptions: CompareWithOption[];
  readonly comparisonKey: Optional<string>;
  readonly hasActionCreationRight: boolean;
  readonly company?: Optional<CompanyResource>;
  readonly appliedFilters: Filter[];
  readonly focusAreas?: FocusAreaResource[];
  readonly onCreateFocusArea: (
    focusArea: Partial<FocusAreaResource>
  ) => void;
  readonly focusAreaLabel: string;
}
export interface totalFavorabilityStateProps {
  isAreYouSureModalVisible: boolean;
  visibleEnpsModal: boolean;
}

class TotalFavorability extends React.Component<Props, totalFavorabilityStateProps> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isAreYouSureModalVisible: false,
      visibleEnpsModal: false
    };
  }
  toggleAreYouSureModal = () => {
    this.setState({ isAreYouSureModalVisible: true });
  };

  toggleAreYouSureEnpsModal = () => {
    this.setState({ visibleEnpsModal: true });
  };

  showAreYouSureEnpsModal(enps: DashboardLikes.ENPS) {
    const { company } = this.props;
    const {numberOfSurveys,numberOfPulsePolls, distribution, score } = enps;
    const { visibleEnpsModal } = this.state;

    if ( (isNotNullNorUndefined(numberOfSurveys) && numberOfSurveys > 0) ||
    (isNotNullNorUndefined(numberOfPulsePolls) && numberOfPulsePolls > 0)) {
      const { promoters, detractors } = calculateNpsDistribution(distribution);

      const surveyText = getOr(numberOfSurveys, 0) > 0 ? `${numberOfSurveys} ${trans("surveys")}` : "";
      const pollText = getOr(numberOfPulsePolls, 0) > 0 ? `${numberOfPulsePolls} ${trans("polls")}` : "";
      const companyName = company?.name;
      const text = surveyText && pollText ? `${surveyText} ${trans("and")} ${pollText}` : surveyText || pollText;
      const  description = <p> 
        <Trans i18nKey="dashboardeNpsCalInfo" values={{companyName}} defaults = {`Based on your employees' responses to 
        the eNPS question such as "I would recommend ${companyName} as a great place to work" (on a 1-10 scale), 
        they are categorized into promoters (scores 9-10), passives (scores 7-8) and detractors (scores 0-6). 
        eNPS is the difference between the percentage of promoters and detractors.`} 
        />
        <br/>
        <Trans i18nKey="dashboardeNpsInfo" 
          values={{  detractors, promoters, text, score }} 
          defaults = {`Among total responses for all the eNPS questions across ${text}, ${promoters}% are promoters 
          and ${detractors}% are detractors, which indicates an eNPS score of ${score}.`} />
      </p>;
    const note = <Trans i18nKey="dashboardeNpsInfoNote" 
    defaults = {`The percentages indicating the promoters and detractors in the histogram 
      are rounded to the nearest whole number for clarity. However, our eNPS calculation uses 
      precise decimal values to ensure accuracy. This may result in a difference of up to 1 
      point between the percentage of promoters/ detractors and the calculated eNPS score.
    `}
  />; 
      return visibleEnpsModal && (
        <FavorabilityOreNPSInfoModal
          visible={visibleEnpsModal}
          title="Your overall eNps score"
          description={description}
          note={note}
          onClose={() => this.setState({ visibleEnpsModal: false })}
        />
      );
    }
  }

  showAreYouSureModal() {
    if (this.areSurveysAndPollsAvailable()) {
      const { isAreYouSureModalVisible } = this.state;
      const { data: {
        numberOfPulsePolls,
        numberOfQuestions,
        numberOfSurveys,
        choiceLabels,
        distribution,
        score,
        numberOfVotes
      } } = this.props;

      const { distributionsText, noteDistributionsText } = generateDistributionText(
        choiceLabels,
        getOr(distribution, []),
        numberOfVotes);

      const surveyText = getOr(numberOfSurveys, 0) > 0 ? `${numberOfSurveys} ${trans("surveys")}` : "";
      const pollText = getOr(numberOfPulsePolls, 0) > 0 ? `${numberOfPulsePolls} ${trans("polls")}` : "";

      const text = surveyText && pollText ? `${surveyText} ${trans("and")} ${pollText}` : surveyText || pollText;
      const des = <Trans i18nKey="overallFavorabilityDes" values={{ numberOfQuestions, text, distributionsText, score }}
        defaults={`Among the responses to the ${numberOfQuestions} Likert-scale questions that are eligible
          for favorability calculation across ${text}, ${distributionsText}
          which results in an overall favorability score of ${score}% for your organization.
          `}
      />;
      const note = <Trans i18nKey="overallFavorabilityNote" values={{ noteDistributionsText }}
        defaults={`The percentages indicating the votes for ${noteDistributionsText} are 
          rounded to the nearest whole number for clarity. However, our favorability calculation uses 
          precise decimal values to ensure accuracy. This may result in a difference of up to 1 percentage
          point between the percentage of votes and the calculated favorability score.
        `}
      />;
      return isAreYouSureModalVisible && (
        <FavorabilityOreNPSInfoModal
          visible={isAreYouSureModalVisible}
          title="Your overall favorability score"
          description={des}
          note={note}
          onClose={() => this.setState({ isAreYouSureModalVisible: false })}
        />
      );
    }
  }
  areSurveysAndPollsAvailable() {
    const { data: { numberOfSurveys, numberOfPulsePolls } } = this.props;
    return (isNotNullNorUndefined(numberOfSurveys) && numberOfSurveys > 0) ||
      (isNotNullNorUndefined(numberOfPulsePolls) && numberOfPulsePolls > 0);
  }

  renderDistributionGraph(
    questionType: QuestionType.ENPS | QuestionType.LIKERT,
    choiceLabels: Optional<string>[] = [],
    distribution: DistributionResult,
    numberOfVotes: number
  ) {
    const { pieceToAdd } = adjustElementsAndPercentages(distribution);

    let barGraphTheme = getBarThemeForFavorability(choiceLabels.length, choiceLabels);
    let labels = choiceLabels;
    let choicesData = distribution;
    if (questionType === QuestionType.ENPS) {
      barGraphTheme = getBarThemeForEnps();
      labels = choiceLabels.slice().reverse();
      choicesData = distribution.slice().reverse();
    }
    const graphData = choicesData.map(({ total, percentage }, index) => {
      const { backgroundColor, fontColor, label } = barGraphTheme[index];
      const labelText = getOr(labels[index], label);

      return ({
        value: total,
        percentage: getOr(percentage, 0),
        percentageWidth: isNotNullNorUndefined(percentage) && percentage > 0 ?
          percentage + pieceToAdd :
          0,
        backgroundColor,
        fontColor,
        label: labelText,
      });
    });

    return <StackedBarGraph data={graphData} width="100%" height="48px" numberOfVotes={numberOfVotes} />;
  };

  eNPSHeader() {
    return   <HeaderContainer width="160px">
    <Heading size="large"><Trans>Overall eNPS</Trans> | </Heading>
    <HeadingSubTitle marginLeft="8px">
      <Trans i18nKey="overallFavorabilitySubTitle"
        defaults={`Overall employee net promoter score (eNPS) is a measure of employee loyalty, 
          calculated from responses to specific eNPS questions across all surveys and polls.`} />
      {
        this.areSurveysAndPollsAvailable() &&
        <HeadingSubTitleUnderline onClick={this.toggleAreYouSureEnpsModal} data-cy='calFav'>
          <Trans>How is this calculated ?</Trans>
        </HeadingSubTitleUnderline>
      }
    </HeadingSubTitle>
  </HeaderContainer>;
  };

  renderENps(eNps: DashboardLikes.ENPS) {
    return (
      <>
        {eNps.numberOfVotes > 0 ? (
          <SectionContainer>
            {this.eNPSHeader()}
            <Summary data-cy="surveyReport_numberOfQuestionsAndVoteForeNPS">
              {eNps.numberOfQuestions} {" "}
              <Trans>{eNps.numberOfQuestions === 1 ? "question" : "questions"}</Trans> •{" "}
              {eNps.numberOfSurveys}{" "}
              <Trans>{eNps.numberOfSurveys === 1 ? "survey" : "surveys"}</Trans> •{" "}
              {eNps.numberOfPulsePolls}{" "}
              <Trans>{eNps.numberOfPulsePolls === 1 ? "poll" : "polls"}</Trans>
            </Summary>
            <FavorabilityContainer>
              <FavorabilityScoreContainer>
                <FavorabilityScore
                  data-cy="surveyReport_enpsScore"
                  favorability={eNps.score}
                  scoreType={ScoreType.NPS}
                  percentage={false} />
              </FavorabilityScoreContainer>
              <FavorabilityDistributionGraphContainer marginLeft = {200}>
                {this.renderDistributionGraph(
                  QuestionType.ENPS,
                  eNps.choiceLabels,
                  eNps.distribution,
                  eNps.numberOfVotes
                )}
              </FavorabilityDistributionGraphContainer>
            </FavorabilityContainer>
          </SectionContainer>
        ): null} 
      </>
    );
  }

  render() {
    const {
      data,
      compareWithOptions,
      comparisonKey,
      hasActionCreationRight,
      company,
      appliedFilters,
      onCreateFocusArea,
      focusAreas,
      focusAreaLabel,
      enps,
      t
    } = this.props;

    const favorabilityChartData = data.favorabilityOverMonths.map(
      ({ month, score }: MonthFavorability) => ([month, score])
    );
    const comparison = extractComparison(data.compare, comparisonKey);
    return (
      <Container>
        <HeaderContainer width="225px">
          <Heading size="large"><Trans>Overall favorability</Trans> | </Heading>
          <HeadingSubTitle marginLeft="8px">
            <Trans i18nKey="overallFavorabilitySubTitle"
              defaults={`Overall favorability is a measure of employee satisfaction 
            across different workplace factors, based on all Likert-scale questions across 
            all surveys and polls.`}/>
            {
              this.areSurveysAndPollsAvailable() &&
              <HeadingSubTitleUnderline onClick={this.toggleAreYouSureModal} data-cy='calFav'>
                <Trans>How is this calculated ?</Trans>
              </HeadingSubTitleUnderline>
            }
          </HeadingSubTitle>
        </HeaderContainer>
        <Summary data-cy="dashboard_questionCount">
          {
            shouldShowModuleCount(appliedFilters, "surveys") || shouldShowModuleCount(appliedFilters, "pulsePolls")
              ? <Trans i18nKey="numberedQuestions"
                values={{count:data.numberOfQuestions}}
                defaults={`${data.numberOfQuestions} questions`}/>
              : null
          }
          {
            shouldShowModuleCount(appliedFilters, "surveys")
              ? <Trans i18nKey="numberedSurveys"
                values={{count:data.numberOfSurveys}}
                defaults={` • ${data.numberOfSurveys} surveys`}/>
              : null
          }
          {
            company && isPulsePollEnabled(company) && shouldShowModuleCount(appliedFilters, "pulsePolls")
              ? <Trans i18nKey="numberedPolls"
                values={{count:data.numberOfPulsePolls}}
                defaults={` • ${data.numberOfPulsePolls} polls`}/>
              : null
          }
        </Summary>

        <SectionContainer>
          <FavorabilityContainer>
            <FavorabilityScoreContainer>
              <FavorabilityScore
                data-cy="dashboard_favourabilityScore"
                favorability={data.score}
                scoreType={ScoreType.FAVORABILITY}
              />
            </FavorabilityScoreContainer>
            {isNotNullNorUndefined(comparison) &&
              <CompareContainer>
                <CompareValueContainer>
                  <CompareValue compare={comparison} /> <Trans>vs previous period</Trans>
                </CompareValueContainer>
              </CompareContainer>}
            <LineChart minMonths={6} labelWithYear={true} data={favorabilityChartData as LineChartData[]} />
          </FavorabilityContainer>
        </SectionContainer>
        {isNotNullNorUndefined(enps) && <SectionContainer marginTop = {80}>
          <NPSSummary>
            {this.renderENps(enps)}
          </NPSSummary>
        </SectionContainer>}

        <SectionContainer marginTop={56}>
          {shouldShowSectionBasedonFilter(appliedFilters, "category") ? <CategoriesContainer>
            <CategoriesColumn data-cy="dashboard_topPerformingCategory">
              {
                isNotEmptyArray(data.bestThree) &&
                <>
                  <Heading size="small" weight="bold"><Trans>Top-performing categories</Trans></Heading>
                  {
                    data.bestThree.map((category: HighlightedCategory) => (
                      <Category
                        showOnHover={true}
                        key={category.category}
                        data={category}
                        compareWithOptions={compareWithOptions}
                        comparisonKey={comparisonKey}
                        fromPage="Dashboard"
                        hasActionCreationRight={hasActionCreationRight}
                        subtitle={generateSegmentSubtitle(t, category.segmentSubtitle, CategorySection.TOP)}
                        onCreateFocusArea={onCreateFocusArea}
                        focusAreas={focusAreas}
                        categoryType={FocusArea.Source.BEST_CATEGORIES}
                        focusAreaLabel={focusAreaLabel}
                      />
                    ))
                  }
                </>
              }
            </CategoriesColumn>
            <CategoriesColumn data-cy="dashboard_worstPerformingCategory">
              {
                isNotEmptyArray(data.worstThree) &&
                <>
                  <Heading size="small" weight="bold"><Trans>Worst-performing categories</Trans></Heading>
                  {
                    data.worstThree.map((category: HighlightedCategory) => (
                      <Category
                        showOnHover={true}
                        key={category.category}
                        data={category}
                        compareWithOptions={compareWithOptions}
                        comparisonKey={comparisonKey}
                        fromPage="Dashboard"
                        hasActionCreationRight={hasActionCreationRight}
                        subtitle={generateSegmentSubtitle(t, category.segmentSubtitle, CategorySection.WORST)}
                        onCreateFocusArea={onCreateFocusArea}
                        focusAreas={focusAreas}
                        categoryType={FocusArea.Source.WORST_CATEGORIES}
                        focusAreaLabel={focusAreaLabel}
                      />
                    ))
                  }
                </>
              }
            </CategoriesColumn>
          </CategoriesContainer> : null }
          <ActionsContainer />
        </SectionContainer>
        {this.showAreYouSureModal()}
        {isNotNullNorUndefined(enps) && this.showAreYouSureEnpsModal(enps)}
      </Container>
    );
  }
}

const Container = styled.div`
  padding: 32px;
  background: ${Palette.white};
  font-family: Lato, sans-serif;
  color: ${Palette.veryDarkBlueGrey};
  @media print {
    padding-top: 70px;
  }
`;


const Summary = styled.div`
  margin-top: 8px;
  color: ${Palette.bluishGrey};
`;

const SectionContainer = styled.div<{ marginTop?: number }>`
  margin-top: ${props => getOr(props.marginTop, 32)}px;
`;

const FavorabilityContainer = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
`;

const FavorabilityScoreContainer = styled.div`
  display: inline-block;

  span {
    font-size: 56px !important;
    line-height: 52px;
  }
`;

const CompareContainer = styled.div`
  display: flex;
  flex: 0 0 160px;
  flex-direction: column;
  justify-content: flex-end;
  height: 52px;
  margin-left: 16px;
  color: ${Palette.bluishGrey};
  font-size: 12px;
`;

const CompareValueContainer = styled.div`
  span {
    font-size: 14px;
  }
`;

const CategoriesContainer = styled.div`
  margin: 24px -16px 0;
  display: flex;
`;

const CategoriesColumn = styled.div`
  flex: 1;
  margin: 0 16px;
`;

const ActionsContainer = styled.div`
  margin-top: 24px;
  text-align: right;
`;

export const HeaderContainer = styled.div<{ width: string }>`
  display: grid;
  grid-template-columns: ${props => props.width} auto;
  flex-direction: column;
`;

export default withTranslation()(TotalFavorability);
